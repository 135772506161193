import {createAction, props} from '@ngrx/store';
import {TimesheetDto} from "../models/timesheet.model";

export const loadPlanLogTimeReport = createAction('[User] Load loadPlanLogTimeReport Report', props<{
  startDate: string | undefined,
  endDate: string | undefined,
  firstLevel: string,
  secondLevel: string,
  thirdLevel: string,
  selectedClient: number[] | undefined,
  logKey: "planLogTime",
  selectedSubContract: number[] | undefined
}>());
export const loadPlanLogTimeReportSuccess = createAction('[User] Load loadPlanLogTimeReportSuccess Report Success', props<{
  timesheetReport: any[]
}>());


export const loadAttendanceLogTimeReport = createAction('[User] Load loadAttendanceLogTimeReport Report', props<{
  startDate: string | undefined,
  endDate: string | undefined,
  firstLevel: string,
  secondLevel: string,
  thirdLevel: string,
  selectedClient: number[] | undefined,
  logKey: "attendanceLogTime",
  selectedSubContract: number[] | undefined
}>());
export const loadAttendanceLogTimeReportSuccess = createAction('[User] Load loadAttendanceLogTimeReportSuccess Report Success', props<{
  timesheetReport: any[]
}>());

export const loadBillableLogTimeReport = createAction('[User] Load loadBillableLogTimeReport Report', props<{
  startDate: string | undefined,
  endDate: string | undefined,
  firstLevel: string,
  secondLevel: string,
  thirdLevel: string,
  selectedClient: number[] | undefined,
  logKey: "billableLogTime",
  selectedSubContract: number[] | undefined
}>());
export const loadBillableLogTimeReportSuccess = createAction('[User] Load loadBillableLogTimeReportSuccess Report Success', props<{
  timesheetReport: any[]
}>());

export const loadNonBillableLogTimeReport = createAction('[User] Load loadNonBillableLogTimeReport Report', props<{
  startDate: string | undefined,
  endDate: string | undefined,
  firstLevel: string,
  secondLevel: string,
  thirdLevel: string,
  selectedClient: number[] | undefined,
  logKey: "nonBillableLogTime",
  selectedSubContract: number[] | undefined
}>());
export const loadNonBillableLogTimeReportSuccess = createAction('[User] Load loadNonBillableLogTimeReportSuccess Report Success', props<{
  timesheetReport: any[]
}>());

export const loadOverTimeWithoutPayLogTimeReport = createAction('[User] Load loadOverTimeWithoutPayLogTimeReport Report', props<{
  startDate: string | undefined,
  endDate: string | undefined,
  firstLevel: string,
  secondLevel: string,
  thirdLevel: string,
  selectedClient: number[] | undefined,
  logKey: "overTimeWithoutPayLogTime",
  selectedSubContract: number[] | undefined
}>());
export const loadOverTimeWithoutPayLogTimeReportSuccess = createAction('[User] Load loadOverTimeWithoutPayLogTimeReportSuccess Report Success', props<{
  timesheetReport: any[]
}>());

export const loadOverTimePayLogTimeReport = createAction('[User] Load loadOverTimePayLogTimeReport Report', props<{
  startDate: string | undefined,
  endDate: string | undefined,
  firstLevel: string,
  secondLevel: string,
  thirdLevel: string,
  selectedClient: number[] | undefined,
  logKey: "overTimePayLogTime",
  selectedSubContract: number[] | undefined
}>());
export const loadOverTimePayLogTimeReportSuccess = createAction('[User] Load loadOverTimePayLogTimeReportSuccess Report Success', props<{
  timesheetReport: any[]
}>());

export const loadInvoicingHoursReport = createAction('[User] Load loadInvoicingHoursReport Report', props<{
  startDate: string | undefined,
  endDate: string | undefined,
  firstLevel: string,
  secondLevel: string,
  thirdLevel: string,
  selectedClient: number[] | undefined,
  logKey: "invoicingHours",
  selectedSubContract: number[] | undefined
}>());
export const loadInvoicingHoursReportSuccess = createAction('[User] Load loadInvoicingHoursReportSuccess Report Success', props<{
  timesheetReport: any[]
}>());


export const loadTimesheetReportFailure = createAction('[User] Load Timesheet Report Failure', props<{ error: any }>());
